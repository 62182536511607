import { useEffect, useMemo, useState } from 'react';
import { useForm, useFormState, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import BackButton from 'src/component/BackButton';
import Button from 'src/component/Button';
import Form from 'src/component/Form';
import FormNumberInput from 'src/component/FormNumberInput';
import FormSelect from 'src/component/FormSelect';
import SelectOption from 'src/component/SelectOption';
import { Page } from 'src/constant/Page';
import { Fiat } from 'src/model/Coin';
import { BidRequestForm } from 'src/model/Form';
import { RootState } from 'src/redux/store';
import { openSnackbar } from 'src/redux/uiSlice';
import { createBidOrder, loadOrderPrice } from 'src/service/orderService';
import { bn, bnFormat } from 'src/util/bigNumber';
import NoResultModal from './component/NoResultModal';

const TradeBid = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { crypto, fiat } = useSelector((state: RootState) => ({
    crypto: state.coin.crypto,
    fiat: state.coin.fiat.filter((value) => value.isTradable),
  }));
  const methods = useForm<BidRequestForm>();
  const formData = useWatch({ control: methods.control });
  const selectedFiat = useMemo<Fiat | undefined>(
    () => fiat.find((value) => value.id === formData.fiat),
    [fiat, formData.fiat],
  );
  const fiatIcon = useMemo(
    () => fiat.find((value) => value.id === formData.fiat)?.icon ?? '',
    [fiat, formData.fiat],
  );
  const paymentMethodOptions = useMemo(() => {
    switch (selectedFiat?.id) {
      case 'cny':
        return ['all', 'bank', 'alipay'];
      case 'vnd':
        return ['all', 'bank', 'vietqr', 'zalopay', 'momo'];
      default:
        return ['all'];
    }
  }, [selectedFiat]);

  const hint = useMemo(() => {
    if (crypto.length === 0) return '';
    const minOrderAmount = crypto[0].minOrderAmount;
    const maxOrderAmount = crypto[0].maxOrderAmount;
    const coin = crypto.length > 0 ? crypto[0].id.toUpperCase() : '';
    if (minOrderAmount && maxOrderAmount)
      return `${t('trade.desc.orderAmount')} ${bnFormat(minOrderAmount)} ${coin} - ${bnFormat(
        maxOrderAmount,
      )} ${coin}`;
    if (minOrderAmount && maxOrderAmount === null)
      return `${t('trade.desc.orderAmount')} ${bnFormat(minOrderAmount)} ${coin} -`;
    if (minOrderAmount === null && maxOrderAmount)
      return `${t('trade.desc.orderAmount')} 0 - ${bnFormat(maxOrderAmount)} ${coin}`;

    return '';
  }, [t, crypto]);

  const [marketPrice, setMarketPrice] = useState<string>();
  const [isNoResultShowing, setNoResultShowing] = useState<boolean>(false);
  const { dirtyFields } = useFormState({ control: methods.control });

  const onSubmit = (data: BidRequestForm) => {
    if (!marketPrice) return;
    createBidOrder(data, marketPrice)
      .then((res) => {
        navigate(`${Page.Order}/${res.id}`, { state: res });
      })
      .catch((e) => {
        if (e === t('error.order.noOrderToMatch')) setNoResultShowing(true);
        dispatch(openSnackbar({ message: e, severity: 'alert' }));
      });
  };
  useEffect(() => {
    //reset amount inputs
    methods.resetField('quoteAmount');
    methods.resetField('baseAmount');
    //reset payment method
    methods.setValue('paymentMethodType', 'all');
  }, [selectedFiat]);

  useEffect(() => {
    if (selectedFiat === undefined) return;
    //update market price
    loadOrderPrice('usdt', selectedFiat.id)
      .then((res) => setMarketPrice(res.price))
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  }, [selectedFiat]);

  useEffect(() => {
    if (dirtyFields.quoteAmount !== true || marketPrice === undefined || crypto.length === 0)
      return;
    const quoteNumber = bn(methods.getValues()['quoteAmount']);
    methods.resetField('baseAmount');
    if (quoteNumber.isGreaterThan(0))
      methods.setValue('baseAmount', quoteNumber.div(marketPrice).toFixed(crypto[0].decimal, 1), {
        shouldDirty: false,
      });
  }, [formData.quoteAmount, dirtyFields.quoteAmount, crypto]);

  useEffect(() => {
    if (dirtyFields.baseAmount !== true || marketPrice === undefined || selectedFiat === undefined)
      return;
    const baseNumber = bn(methods.getValues()['baseAmount']);
    methods.resetField('quoteAmount');
    if (baseNumber.isGreaterThan(0))
      methods.setValue(
        'quoteAmount',
        baseNumber.times(marketPrice).toFixed(selectedFiat.decimal, 0),
        {
          shouldDirty: false,
        },
      );
  }, [formData.baseAmount, dirtyFields.baseAmount, selectedFiat]);

  useEffect(() => {
    if (!formData.baseAmount || crypto.length === 0) return;

    const minOrderAmount = crypto[0].minOrderAmount;
    const maxOrderAmount = crypto[0].maxOrderAmount;
    if (minOrderAmount && bn(formData.baseAmount).lt(minOrderAmount))
      methods.setError('baseAmount', {});
    else if (maxOrderAmount && bn(formData.baseAmount).gt(maxOrderAmount))
      methods.setError('baseAmount', {});
    else methods.clearErrors('baseAmount');
  }, [formData.baseAmount, crypto]);

  return (
    <div>
      <BackButton />
      <div className="mb-[30px] mt-[10px] text-[28px] font-bold sm:mt-[20px] sm:text-[32px]">
        {t('trade.heading')}
      </div>
      {fiat.length > 0 && crypto.length > 0 && (
        <Form
          methods={methods}
          onSubmit={onSubmit}
          className="box-border rounded-[12px] bg-white px-[40px] pb-[70px] pt-[40px] dark:bg-black-800 md:w-1/2"
        >
          <div className="relative">
            <FormSelect label={t('trade.desc.currency')} name="fiat" defaultValue="cny" required>
              {fiat.map((v) => (
                <SelectOption key={v.id} value={v.id}>
                  {v.id.toUpperCase()}
                </SelectOption>
              ))}
            </FormSelect>
          </div>
          <div className="relative mt-[45px]">
            <FormNumberInput
              label={t('trade.desc.pay')}
              name="quoteAmount"
              decimal={selectedFiat?.decimal ?? 0}
              required
            />
            <div className="absolute right-[0px] top-[28px] text-grey-700 dark:text-grey-300">
              {selectedFiat?.id.toUpperCase()}
            </div>
          </div>
          <div className="relative mt-[45px]">
            <FormNumberInput
              name="baseAmount"
              label={t('trade.desc.receive')}
              hint={hint}
              decimal={crypto[0].decimal}
              required
            />
            <div className="absolute right-[0px] top-[28px] text-grey-700 dark:text-grey-300">
              {crypto[0].id.toUpperCase()}
            </div>
          </div>
          <div className="mt-[25px] text-[14px] text-dark-700 dark:text-grey-200">{`${t(
            'trade.desc.marketPrice',
          )} ${marketPrice ? `${fiatIcon} ${bnFormat(marketPrice)}` : '-'}`}</div>
          <div className="mt-5">
            <FormSelect
              label={t('trade.desc.paymentMethod')}
              name="paymentMethodType"
              defaultValue="all"
              required
            >
              {paymentMethodOptions.map((v) => (
                <SelectOption key={v} value={v}>
                  {t(`trade.desc.paymentMethodDisplay.${v}`)}
                </SelectOption>
              ))}
            </FormSelect>
          </div>
          <div className="mt-[45px] text-right">
            <Button
              type="submit"
              disabled={
                !formData.baseAmount ||
                !formData.quoteAmount ||
                Object.keys(methods.formState.errors).length !== 0
              }
            >
              {t('trade.act.pairOrder')}
            </Button>
          </div>
        </Form>
      )}
      <NoResultModal open={isNoResultShowing} onClose={() => setNoResultShowing(false)} />
    </div>
  );
};

export default TradeBid;
