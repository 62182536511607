import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import Select, { Props as SelectProps } from './Select';

/**
 * usage note:
 * Please see Form.tsx
 */

type Props = SelectProps & {
  name: string;
  required?: boolean;
};

const FormSelect = ({ name, children, required = false, defaultValue, ...props }: Props) => {
  const {
    setValue,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    if (defaultValue) setValue(name, defaultValue);
    if (required && !defaultValue && !getValues(name)) setError(name, { types: { required } });
  }, [required, defaultValue]);

  const onChange = (value: string) => {
    setValue(name, value);
    clearErrors(name);
  };

  return (
    <Select
      value={getValues(name)}
      defaultValue={defaultValue}
      onChange={onChange}
      error={!!errors[name]}
      {...props}
    >
      {children}
    </Select>
  );
};

export default FormSelect;
