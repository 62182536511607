import announcementEndpoint from 'src/api/announcementEndpoint';
import { AdminAnnouncementContent, AnnouncementLangs } from 'src/model/Announcement';
import { AnnouncementForm } from 'src/model/Form';
import { PaginationParams } from 'src/model/Pagination';
import { dispatch } from 'src/redux/store';
import { finishWaiting, reduceUnreadAnnouncement, startWaiting } from 'src/redux/uiSlice';

export const getAnnouncementList = async (params?: PaginationParams) => {
  try {
    dispatch(startWaiting());
    const res = await announcementEndpoint.getAnnouncement({ ...params, order: 'desc' });

    return { data: res.data, count: Number(res.headers['x-pagination-count']) };
  } finally {
    dispatch(finishWaiting());
  }
};

export const getAdminAnnouncementList = async (params?: PaginationParams) => {
  try {
    dispatch(startWaiting());
    const res = await announcementEndpoint.getAnnouncement({ ...params, order: 'desc' });

    return { data: res.data, count: Number(res.headers['x-pagination-count']) };
  } finally {
    dispatch(finishWaiting());
  }
};

export const createAnnouncement = async (data: AnnouncementForm) => {
  const subjects: AdminAnnouncementContent = {
    'zh-cn': data['zh-cn-subject'],
  };
  const contents: AdminAnnouncementContent = {
    'zh-cn': data['zh-cn-content'],
  };
  AnnouncementLangs.filter((lang) => lang !== 'zh-cn').forEach((lang) => {
    const subject = data[`${lang}-subject`];
    const content = data[`${lang}-content`];

    if (subject && content) {
      subjects[lang] = subject;
      contents[lang] = content;
    }
  });

  try {
    dispatch(startWaiting());
    await announcementEndpoint.createAnnouncement({
      side: data.side,
      subjects,
      contents,
      publishedAt: data.publishedAt,
      unpinnedAt: data.unpinnedAt === '' ? null : data.unpinnedAt,
      unpublishedAt: data.unpublishedAt === '' ? null : data.unpublishedAt,
    });
  } finally {
    dispatch(finishWaiting());
  }
};

export const updateAnnouncement = async (id: string, data: AnnouncementForm) => {
  const subjects: AdminAnnouncementContent = {
    'zh-cn': data['zh-cn-subject'],
  };
  const contents: AdminAnnouncementContent = {
    'zh-cn': data['zh-cn-content'],
  };
  AnnouncementLangs.filter((lang) => lang !== 'zh-cn').forEach((lang) => {
    const subject = data[`${lang}-subject`];
    const content = data[`${lang}-content`];
    if (subject && content) {
      subjects[lang] = subject;
      contents[lang] = content;
    }
  });

  try {
    dispatch(startWaiting());
    await announcementEndpoint.updateAnnouncement(id, {
      subjects,
      contents,
      publishedAt: data.publishedAt,
      unpinnedAt: data.unpinnedAt === '' ? null : data.unpinnedAt,
      unpublishedAt: data.unpublishedAt === '' ? null : data.unpublishedAt,
    });
  } finally {
    dispatch(finishWaiting());
  }
};

export const getAnnouncementById = async (id: string) => {
  try {
    dispatch(startWaiting());
    const res = await announcementEndpoint.getAnAnnouncement(id);

    if (res.data.readAt === null) {
      await announcementEndpoint.readAnnouncement(id);
      dispatch(reduceUnreadAnnouncement());
    }

    return res.data;
  } finally {
    dispatch(finishWaiting());
  }
};

export const deleteAnnouncementById = async (id: string) => {
  try {
    dispatch(startWaiting());
    const res = await announcementEndpoint.deleteAnnouncement(id);

    return res.data;
  } finally {
    dispatch(finishWaiting());
  }
};
