import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetConfigsResponse, GetExchangeRateResponse } from 'src/model/Api';
import { Rebate } from 'src/model/Rebate';

export type SettingState = {
  config: GetConfigsResponse | null;
  exchangeRateCNY: GetExchangeRateResponse | null;
  exchangeRateVND: GetExchangeRateResponse | null;
  bidPriceCNY: string | null;
  bidPriceVND: string | null;
  parentRebatePercentage: string | null;
  agentRebate: Rebate[];
};

const initialState: SettingState = {
  config: null,
  exchangeRateCNY: null,
  exchangeRateVND: null,
  bidPriceCNY: null,
  bidPriceVND: null,
  parentRebatePercentage: null,
  agentRebate: [],
};

export const settingSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setConfig: (state: SettingState, action: PayloadAction<GetConfigsResponse>) => {
      state.config = action.payload;
    },
    setExchangeRateCNY: (state: SettingState, action: PayloadAction<GetExchangeRateResponse>) => {
      state.exchangeRateCNY = action.payload;
    },
    setExchangeRateVND: (state: SettingState, action: PayloadAction<GetExchangeRateResponse>) => {
      state.exchangeRateVND = action.payload;
    },
    setBidPriceCNY: (state: SettingState, action: PayloadAction<string>) => {
      state.bidPriceCNY = action.payload;
    },
    setBidPriceVND: (state: SettingState, action: PayloadAction<string>) => {
      state.bidPriceVND = action.payload;
    },
    setParentRebatePercentage: (state: SettingState, action: PayloadAction<string>) => {
      state.parentRebatePercentage = action.payload;
    },
    setAgentRebate: (state: SettingState, action: PayloadAction<Rebate[]>) => {
      state.agentRebate = action.payload;
    },
  },
});

export const {
  setConfig,
  setExchangeRateCNY,
  setExchangeRateVND,
  setBidPriceCNY,
  setBidPriceVND,
  setParentRebatePercentage,
  setAgentRebate,
} = settingSlice.actions;

export default settingSlice.reducer;
