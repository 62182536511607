import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { useMediaPredicate } from 'react-media-hook';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import BackButton from 'src/component/BackButton';
import Button from 'src/component/Button';
import Checkbox from 'src/component/Checkbox';
import Tabs from 'src/component/Tabs';
import { Ui } from 'src/constant/Env';
import { MediaQuery } from 'src/constant/Media';
import { Page } from 'src/constant/Page';
import { ThemeContext } from 'src/context/ThemeContext';
import { BankAccount } from 'src/model/Bank';
import { RootState } from 'src/redux/store';
import { openSnackbar } from 'src/redux/uiSlice';
import { deleteBankAccount, getAccountInfo } from 'src/service/accountService';
import AccountCard from './component/AccountCard';
import AccountTable from './component/AccountTable';
import DisableTfaModal from './component/DisableTfaModal';
import EditDisplayNameModal from './component/EditDisplayNameModal';
import InvitationModal from './component/InvitationModal';
import ResetPasswordModal from './component/ResetPasswordModal';

const Account = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { IcEdit, IcCopy, IcQrcode, IcBatch, IcClosePrimary } = useContext(ThemeContext).image;
  const [isEditDisplayNameModalOpen, setEditDisplayNameModalOpen] = useState<boolean>(false);
  const [isResetPasswordModalOpen, setResetPasswordModalOpen] = useState<boolean>(false);
  const [isDisableTfaModalOpen, setDisableTfaModalOpen] = useState<boolean>(false);
  const [isInivationModalOpen, setInivationModalOpen] = useState<boolean>(false);
  const isBiggerThanMd = useMediaPredicate(MediaQuery.Md);
  const [bankAccount, setBankAccount] = useState<BankAccount[]>([]);
  const [refresh, setRefresh] = useState<boolean>(false);
  const { accountInfo: info } = useSelector((rootState: RootState) => rootState.auth);
  const [isBatchClick, setIsBatchClick] = useState<boolean>(false);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [selected, setSelected] = useState<BankAccount[]>([]);
  const [tab, setTab] = useState<number>(0);

  const filteredBankAccount = useMemo(() => {
    if (isBiggerThanMd === false) {
      if (tab === 0)
        return bankAccount.filter((v) => v.paymentMethodType === 'bank' && v.nationality === 'CN');
      if (tab === 1)
        return bankAccount.filter((v) => v.paymentMethodType === 'bank' && v.nationality === 'VN');
      if (tab === 2) return bankAccount.filter((v) => v.paymentMethodType === 'alipay');
      if (tab === 3) return bankAccount.filter((v) => v.paymentMethodType === 'vietqr');
      if (tab === 4) return bankAccount.filter((v) => v.paymentMethodType === 'zalopay');
      if (tab === 5) return bankAccount.filter((v) => v.paymentMethodType === 'momo');
    }

    return bankAccount;
  }, [isBiggerThanMd, tab, bankAccount]);

  useEffect(() => {
    if (Ui !== 'seller') return;
    getAccountInfo()
      .then((resBankAccount) => setBankAccount(resBankAccount))
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  }, [refresh]);

  useEffect(() => {
    const temp = [...selected].map((v) => v.id);
    if (isBatchClick === false) setSelectAll(false);
    else if (filteredBankAccount.every((v) => temp.includes(v.id))) setSelectAll(true);
    else setSelectAll(false);
  }, [isBatchClick, filteredBankAccount, selected]);

  const onCopy = () => {
    dispatch(openSnackbar({ message: t('message.copied') }));
  };

  const onClickPasswordEdit = () => {
    setResetPasswordModalOpen(true);
  };

  const onClickQrcode = () => {
    setInivationModalOpen(true);
  };

  const onClickSlugEdit = () => {
    setEditDisplayNameModalOpen(true);
  };

  const onClickAdd = () => {
    navigate(Page.CreateAccount);
  };

  const onClickTfaEnable = (type: 'disable' | 'enable') => () => {
    if (type === 'enable') navigate(Page.EnableTfa);
    else setDisableTfaModalOpen(true);
  };

  const onClickDelete = (id: string) => {
    deleteBankAccount(id)
      .then(() => setRefresh(!refresh))
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  };

  const onSelectAll = useCallback(() => {
    const temp = [...selected];
    filteredBankAccount.forEach((ba) => {
      const idx = temp.findIndex((v) => v.id === ba.id);
      if (selectAll === false && idx < 0) temp.push(ba);
      else if (selectAll === true && idx >= 0) temp.splice(idx, 1);
    });
    setSelected(temp);
  }, [selected, filteredBankAccount, selectAll]);

  const onSelectOne = useCallback(
    (ba: BankAccount) => {
      const temp = [...selected];
      const idx = temp.findIndex((v) => v.id === ba.id);
      if (idx >= 0) temp.splice(idx, 1);
      else temp.push(ba);
      setSelected(temp);
    },
    [selected],
  );

  const onBatchDelete = () => {
    navigate(Page.AccountBatchConfirm, {
      replace: true,
      state: selected,
    });
  };

  return (
    <div>
      <BackButton />
      <div className="mt-[10px] text-[28px] font-semibold sm:mt-[20px] sm:text-[32px]">
        {t('account.heading')}
      </div>
      <div className="mt-[28px] flex flex-wrap text-[16px]">
        <div className="box-border h-[68px] w-full pb-[26px] xs:w-1/2 xs:px-[15px] lg:px-[30px]">
          <div className="w-full text-grey-700 dark:text-grey-300">{t('account.desc.id')}</div>
          <div className="flex min-h-[36px] items-center justify-between">
            <div>{info?.id}</div>
            <CopyToClipboard onCopy={onCopy} text={info?.id ?? ''}>
              <img
                src={IcCopy}
                className="box-border h-[36px] w-[36px] cursor-pointer px-[8px] py-[0px]"
              />
            </CopyToClipboard>
          </div>
        </div>
        <div className="box-border h-[68px] w-full pb-[26px] xs:w-1/2 xs:px-[15px] lg:px-[30px]">
          <div className="w-full text-grey-700 dark:text-grey-300">
            {t('account.desc.nickName')}
          </div>
          <div className="flex min-h-[36px] items-center justify-between">
            <div>{info?.slug}</div>
            <img
              className="h-[36px] w-[36px] cursor-pointer"
              src={IcEdit}
              onClick={onClickSlugEdit}
            />
          </div>
        </div>
        <div className="box-border h-[68px] w-full pb-[26px] xs:w-1/2 xs:px-[15px] lg:px-[30px]">
          <div className="w-full text-grey-700 dark:text-grey-300">{t('account.desc.email')}</div>
          <div className="flex min-h-[36px] items-center justify-between">
            <div>{info?.email}</div>
          </div>
        </div>
        <div className="box-border h-[68px] w-full pb-[26px] xs:w-1/2 xs:px-[15px] lg:px-[30px]">
          <div className="w-full text-grey-700 dark:text-grey-300">
            {t('account.desc.loginPassword')}
          </div>
          <div className="flex min-h-[36px] items-center justify-between">
            <div>{'********'}</div>
            <img
              className="h-[36px] w-[36px] cursor-pointer"
              src={IcEdit}
              onClick={onClickPasswordEdit}
            />
          </div>
        </div>
        <div className="box-border h-[68px] w-full pb-[26px] xs:w-1/2 xs:px-[15px] lg:px-[30px]">
          <div className="w-full text-grey-700 dark:text-grey-300">
            {t('account.desc.twoStepVerification')}
          </div>
          <div className="flex min-h-[36px] items-center justify-between">
            <div>{info?.otpActivated ? t('account.desc.enabled') : t('account.desc.disabled')}</div>
            {info && (
              <Button
                appearance="text"
                onClick={onClickTfaEnable(info.otpActivated ? 'disable' : 'enable')}
              >
                {info.otpActivated ? t('account.act.disable') : t('account.act.enable')}
              </Button>
            )}
          </div>
        </div>
        {Ui === 'seller' && (
          <div className="box-border h-[68px] w-full pb-[26px] xs:w-1/2 xs:px-[15px] lg:px-[30px]">
            <div className="w-full text-grey-700 dark:text-grey-300">
              {t('account.desc.inviteCode')}
            </div>
            <div className="flex min-h-[36px] items-center justify-between">
              <div>{info?.id}</div>
              <img
                className="h-[36px] w-[36px] cursor-pointer"
                src={IcQrcode}
                onClick={onClickQrcode}
              />
            </div>
          </div>
        )}
      </div>
      <div className="h-[1px] bg-light-200 dark:bg-dark-700 sm:mx-[15px] lg:mx-[30px]" />
      {Ui === 'seller' && (
        <div>
          <div className="mb-[15px] mt-5 flex items-center justify-between text-base font-bold sm:mx-[15px] lg:mx-[30px]">
            <div>{t('account.desc.paymentMethods')}</div>
            <Button size="small" onClick={() => onClickAdd()}>
              {t('account.act.add')}
            </Button>
          </div>
          {!isBiggerThanMd && (
            <div className="mb-[25px] sm:mx-[15px] lg:mx-[30px]">
              <Tabs
                labels={[
                  t('bankAccount.desc.paymentType.bankCN'),
                  t('bankAccount.desc.paymentType.bankVN'),
                  t('bankAccount.desc.paymentType.alipay'),
                  t('bankAccount.desc.paymentType.vietqr'),
                  t('bankAccount.desc.paymentType.zalopay'),
                  t('bankAccount.desc.paymentType.momo'),
                ]}
                defaultIndex={tab}
                onChange={(v) => setTab(v)}
              />
            </div>
          )}
          <div className="sm:mx-[15px] md:mt-[30px] lg:mx-[30px]">
            <div className="mb-[15px] flex md:justify-end">
              {isBatchClick ? (
                <div className="flex w-full items-end justify-between md:justify-end">
                  <div className="flex items-center gap-6">
                    <Button
                      appearance="text"
                      disabled={selected.length === 0}
                      onClick={onBatchDelete}
                    >
                      {t('myTrade.act.delete')}
                    </Button>
                    <img
                      src={IcClosePrimary}
                      className="-order-1 cursor-pointer md:order-1"
                      onClick={() => {
                        setIsBatchClick(false);
                        setSelected([]);
                      }}
                    />
                  </div>
                  <div className="md:hidden">
                    <Checkbox checked={selectAll} onChange={onSelectAll} />
                  </div>
                </div>
              ) : (
                <div
                  className="flex cursor-pointer items-center"
                  onClick={() => setIsBatchClick(true)}
                >
                  <img src={IcBatch} />
                  <Button appearance="text">{t('account.act.batch')}</Button>
                </div>
              )}
            </div>
            {isBiggerThanMd ? (
              <AccountTable
                data={filteredBankAccount}
                onClickDelete={onClickDelete}
                isBatchClick={isBatchClick}
                selected={selected}
                selectAll={selectAll}
                onSelectAll={onSelectAll}
                onSelectOne={onSelectOne}
              />
            ) : (
              <AccountCard
                data={filteredBankAccount}
                onClickDelete={onClickDelete}
                isBatchClick={isBatchClick}
                selected={selected}
                onSelectOne={onSelectOne}
              />
            )}
          </div>
        </div>
      )}
      <EditDisplayNameModal
        open={isEditDisplayNameModalOpen}
        handleClose={() => setEditDisplayNameModalOpen(false)}
      />
      <ResetPasswordModal
        open={isResetPasswordModalOpen}
        handleClose={() => setResetPasswordModalOpen(false)}
      />
      <DisableTfaModal
        open={isDisableTfaModalOpen}
        handleClose={() => setDisableTfaModalOpen(false)}
      />
      <InvitationModal
        open={isInivationModalOpen}
        handleClose={() => setInivationModalOpen(false)}
        userId={info?.id ?? 'xxx'}
      />
    </div>
  );
};

export default Account;
